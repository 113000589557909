import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import API from "../../middleware/Api";
import { getSpliceStatus, getTaskProgress, getTaskStatus, getTaskType } from "../../config";
import Pagination from "@material-ui/lab/Pagination";
import { formatLocalTime } from "../../utils/datetime";
import { toggleSnackbar } from "../../redux/explorer";
import Nothing from "../Placeholder/Nothing";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: "auto",
        marginTop: "50px",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
            width: 1100,
            marginLeft: "auto",
            marginRight: "auto",
        },
        marginBottom: "50px",
    },
    content: {
        marginTop: theme.spacing(4),
        overflowX: "auto",
    },
    cardContent: {
        padding: theme.spacing(2),
    },
    tableContainer: {
        overflowX: "auto",
    },
    create: {
        marginTop: theme.spacing(2),
    },
    noWrap: {
        wordBreak: "keepAll",
    },
    footer: {
        padding: theme.spacing(2),
    },
}));

export default function Splices() {
    const { t } = useTranslation();
    const { t: tDashboard } = useTranslation("dashboard");
    const [tasks, setTasks] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);

    const dispatch = useDispatch();
    const ToggleSnackbar = useCallback(
        (vertical, horizontal, msg, color) =>
            dispatch(toggleSnackbar(vertical, horizontal, msg, color)),
        [dispatch]
    );

    const loadList = (page) => {
        API.get("/user/setting/splices?page=" + page)
            .then((response) => {
                setTasks(response.data.tasks);
                setTotal(response.data.total);
            })
            .catch((error) => {
                ToggleSnackbar("top", "right", error.message, "error");
            });
    };

    const intervalRef = useRef(null);

    useEffect(()=>{
        // 清除之前的定时器
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        // 开始新的定时轮询
        intervalRef.current = setInterval(() => {
            loadList(page);
        }, 5000);

        return () => {
            // 组件卸载时清除定时器
            clearInterval(intervalRef.current);
        };
    },[page])


    useEffect(() => {
        loadList(page);
        // eslint-disable-next-line
    }, [page]);

    const getError = (error) => {
        if (error === "") {
            return "-";
        }
        try {
            const res = JSON.parse(error);
            return `${res.msg}: ${res.error}`;
        } catch (e) {
            return t("uploader.unknownStatus");
        }
    };

    const classes = useStyles();

    return (
        <div className={classes.layout}>
            <Typography color="textSecondary" variant="h4">
                {t("navbar.splicesQueue")}
            </Typography>
            <Paper elevation={3} className={classes.content}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell nowrap="nowrap" align="center">
                                {t("modals.fileName")}
                            </TableCell>
                            <TableCell nowrap="nowrap" align="center">
                                {t("setting.bucket")}
                            </TableCell>
                            <TableCell nowrap="nowrap" align="center">
                                {t("setting.taskStatus")}
                            </TableCell>
                            <TableCell nowrap="nowrap" align="center">
                                {t("setting.createdAt")}
                            </TableCell>
                            <TableCell nowrap="nowrap" align="center">
                                {t("fileManager.lastModified")}
                            </TableCell>
                            <TableCell nowrap="nowrap" align="center">
                                {t("setting.option")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tasks.map((row, id) => (
                            <TableRow key={id}>
                                <TableCell
                                    nowrap="nowrap"
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {row.file_name}
                                </TableCell>
                                <TableCell nowrap="nowrap" align="center" >
                                    {row.bucket_name}
                                </TableCell>
                                <TableCell nowrap="nowrap" align="center">
                                    {getSpliceStatus(row.status)}
                                </TableCell>
                                <TableCell nowrap="nowrap" align="center">
                                    {formatLocalTime(row.created_at)}
                                </TableCell>
                                <TableCell nowrap="nowrap" align="center">
                                    {formatLocalTime(row.updated_at)}
                                </TableCell>
                                <TableCell nowrap="nowrap" align="center">
                                    {
                                        row.status===4&&
                                        <Button color="primary" onClick={()=>{
                                            API.get("/user/setting/splices_retry?id="+row.id)
                                        }}>
                                            {t("uploader.retry")}
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {tasks.length === 0 && (
                    <Nothing primary={t("setting.listEmpty")} />
                )}
                <div className={classes.footer}>
                    <Pagination
                        count={Math.ceil(total / 10)}
                        onChange={(e, v) => setPage(v)}
                        color="secondary"
                    />
                </div>
            </Paper>
        </div>
    );
}
