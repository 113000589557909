import React, { useCallback, useEffect, useRef } from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    makeStyles,
    TextField,
} from "@material-ui/core";
import { toggleSnackbar } from "../../redux/explorer";
import { useDispatch } from "react-redux";
import API from "../../middleware/Api";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LockIcon from "@material-ui/icons/Lock";
import TimerIcon from "@material-ui/icons/Timer";
import CasinoIcon from "@material-ui/icons/Casino";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ToggleIcon from "material-ui-toggle-icon";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    widthAnimation: {},
    shareUrl: {
        minWidth: "400px",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.light,
        position: "absolute",
        top: "50%",
        left: "50%",
    },
    flexCenter: {
        alignItems: "center",
    },
    noFlex: {
        display: "block",
    },
    scoreCalc: {
        marginTop: 10,
    },
    expireLabel: {
        whiteSpace: "nowrap",
    },
}));


export default function CreatLink(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [shareURL, setShareURL] = React.useState(" ");

    const onClose = () => {
        props.onClose();
        setTimeout(() => {
            setShareURL(" ");
        }, 500);
    };

    const copySource = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(shareURL);
        }
    };

    const handleFocus = (event) => {
        event.target.select()
        // console.log(props.selected[0]['id'])
        // if(props.selected.length>0){
        //     API.get("/share/videoLink?id=",props.selected[0]['id'])
        //         .then((res)=>{
        //             console.log(res)
        //             // setShareURL(res.data)
        //         })
        // }
    }

    const dispatch = useDispatch();

    useEffect(()=>{
        if(props.selected.length>0&&props.open){
            API.get("/share/videoLink?id="+props.selected[0]['id'])
                .then((res)=>{
                    if(res.status===200){
                        setShareURL(res.data)
                    }
                }).catch((error) => {
                dispatch(
                    toggleSnackbar("top", "right", error.message, "error")
                );
            })
        }
    },[props.open])

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            className={classes.widthAnimation}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">
                {t("fileManager.createVideoPlayLink")}
            </DialogTitle>

            <DialogContent>
                <TextField
                    onFocus={handleFocus}
                    autoFocus
                    inputProps={{ readonly: true }}
                    label={t("setting.webdavServer")}
                    value={shareURL}
                    variant="outlined"
                    fullWidth
                />
            </DialogContent>

            <DialogActions>
                <div className={classes.wrapper}>
                    <Button onClick={copySource} color="secondary">
                        {t("copyToClipboard", { ns: "common" })}
                    </Button>
                </div>
                <Button onClick={onClose}>
                    {t("close", { ns: "common" })}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
